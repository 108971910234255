<template>
    <div>
        <b-card no-body>
            <b-tabs card>
                <b-tab title="Primary Information" active>
                    <b-card-text>
                        <b-form-group label="Request ID" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.requestId }}
                        </b-form-group>

                        <b-form-group label="User" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.firstName + ' ' + row.item.lastName }}
                        </b-form-group>

                        <b-form-group label="Current Company" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.currCompany }}
                        </b-form-group>

                        <b-form-group label="New Company" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.newCompany }}
                        </b-form-group>

                        <b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateCreated) }}
                        </b-form-group>

                        <b-form-group label="Created By" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.createdBy }}
                        </b-form-group>

                        <b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateUpdated) }}
                        </b-form-group>

                        <b-form-group label="Updated By" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.updatedBy }}
                        </b-form-group>

                        <b-form-group label="Status" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span v-if="row.item.status === 'Open'">
                                <b-badge variant="success">Open</b-badge>
                            </span>
                            <span v-else-if="row.item.status === 'Approved'">
                                <b-badge variant="primary">Approved</b-badge>
                            </span>
                            <span v-else>
                                <b-badge variant="danger">Rejected</b-badge>
                            </span>
                        </b-form-group>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

export default {
    name: 'transfer-request-details-view',
    components: {},
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        }
    },
};
</script>