<template>
    <b-modal id="confirm-transfer-requests" ref="confirm-transfer-requests" :title="title" ok-title="Approve All"
        cancel-title="Reject All" @cancel="rejectTransferRequests" @ok="approveTransferRequests"
        :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" cancel-variant="danger"
        ok-variant="primary" :no-close-on-backdrop="true" size="lg">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-container fluid>
            <b-row class="mb-2">
                <b-col sm="12">
                    You have selected
                    <b>{{ transferRequests.length }}</b> transfer company requests with
                    <b-badge variant="success">Open</b-badge> status as
                    follows
                </b-col>
            </b-row>
            <b-row class="my-4">
                <b-col sm="12">
                    <b-table show-empty striped hover fixed :items="transferRequests" :fields="fields"
                        :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
                        <template v-slot:cell(user)="row">
                            {{ row.item.firstName + ' ' + row.item.lastName }}
                        </template>

                        <template v-slot:cell(currCompany)="row">
                            {{ row.item.currCompany }}
                            <br />
                            <span class="company-description">{{ getCompanyDescription(row.item.currCompanyId) }}</span>
                        </template>

                        <template v-slot:cell(newCompany)="row">
                            {{ row.item.newCompany }}
                            <br />
                            <span class="company-description">{{ getCompanyDescription(row.item.newCompanyId) }}</span>
                        </template>
                    </b-table>
                </b-col>
            </b-row>

        </b-container>
    </b-modal>
</template>

<script>
// API
import requestApi from '@/api/requestApi';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'confirm-transfer-requests',
    components: {
        Loading,
    },
    props: {
        allCompaniesObj: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fields: [
                {
                    key: 'requestId',
                    sortable: true,
                },
                {
                    key: 'user',
                    sortable: false,
                },
                {
                    key: 'currCompany',
                    label: "Current Company",
                    sortable: true,
                },
                {
                    key: 'newCompany',
                    sortable: false,
                }
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            transferRequests: {},
            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,

            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        title() {
            return 'Confirm Transfer Company Request(s) ';
        },
        disableConfirmButtons() {
            return this.isLoading;
        }
    },
    mounted() {
        EventBus.$on('onUpdateSelRequestsToConfirm', (transferRequests) => {
            this.transferRequests = [];
            _.forEach(transferRequests, request => {
                if (request.status === 'Open') {
                    this.transferRequests.push(request);
                }
            });
        });
    },
    methods: {
        getCompanyDescription(companyId) {
            let companyObj = this.allCompaniesObj[companyId];
            if (companyObj && !_.isEmpty(companyObj) && companyObj.description) {
                return companyObj.description;
            }
            return "-"
        },

        async rejectTransferRequests(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            let requests = {};
            _.forEach(this.transferRequests, transferRequest => {
                transferRequest.status = 'Rejected';
                transferRequest.updatedBy = this.loggedUser.id;
                transferRequest.dateUpdated = DateUtil.getCurrentTimestamp();

                requests[transferRequest.id] = transferRequest;
            });

            await this.saveTransferRequest(requests, false);
        },
        async approveTransferRequests(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            if (!this.validateRequests()) {
                this.$toaster.warning("Some selected request(s) have no company to transfer to. These requests must be REJECTED instead.");
                return;
            }

            let requests = {};
            _.forEach(this.transferRequests, transferRequest => {
                transferRequest.status = 'Approved';
                transferRequest.updatedBy = this.loggedUser.id;
                transferRequest.dateUpdated = DateUtil.getCurrentTimestamp();

                requests[transferRequest.id] = transferRequest;
            });

            await this.saveTransferRequest(requests, true);
        },
        validateRequests() {
            let isValid = true;
            for (let transferRequest in this.transferRequests) {
                if (!transferRequest.newCompany) {
                    isValid = false;
                    break;
                }
            }
            return isValid;
        },
        async saveTransferRequest(requests, isApproved) {
            // show loading indicator
            this.isLoading = true;

            try {
                let { data } = await requestApi.confirmTransferCompanyRequests(requests, this.loggedUser.id);

                if (data.isSuccess) {

                    if (isApproved) {
                        this.$toaster.success("Transfer Request(s) were all successfully APPROVED.");
                    } else {
                        this.$toaster.success("Transfer Request(s) were all successfully REJECTED.");
                    }

                    this.$store.dispatch('updateAllUsers', data.usersObj);
                    EventBus.$emit('onConfirmTransferRequests', data.transferRequests);
                    this.$refs['confirm-transfer-requests'].hide();

                } else {
                    this.$toaster.error("Error encountered while confirming transfer request(s). Please try again later.");
                }
            } catch (_error) {
                this.$toaster.error("Error encountered while confirming transfer request(s). Please try again later.");
            }

            // hide loading indicator
            this.isLoading = false;
        }
    },
};
</script>

<style scoped>
.company-description {
    font-size: 11px;
}
</style>