import { db } from '@/config/firebase';

function generateQuery(filter) {
    let query = db.collection("transferCompanyRequests");

    if (filter.requestId && filter.requestId.length > 0) {
        query = query.where("requestId", "==", filter.requestId);
    } else {
        if (filter.fromTimestamp && filter.fromTimestamp > 0) {
            query = query.where("dateCreated", ">=", filter.fromTimestamp);
        }

        if (filter.toTimestamp && filter.toTimestamp > 0) {
            query = query.where("dateCreated", "<=", filter.toTimestamp);
        }

        if (filter.company && filter.company.id && filter.company.id.length > 0) {
            query = query.where("currCompanyId", "==", filter.company.id);
        }

        if (filter.status && filter.status.length > 0) {
            query = query.where("status", "==", filter.status);
        }
    }

    return query;
}

function getTransferCompanyRequestListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

export default {
    getTransferCompanyRequestListener
}